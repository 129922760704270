import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../common/headerSlice'
import modalSlice from '../common/modalSlice'

const combinedReducer = {
    header : headerSlice,
    modal : modalSlice,
}

export default configureStore({
    reducer: combinedReducer
})